/* eslint-disable */
window.GO_API_URL = document.querySelector('[name=GO_API_URL]').content;
window.GRAPHQL_API_URL = document.querySelector('[name=GRAPHQL_API_URL]').content;
window.APP_ENV = document.querySelector('[name=APP_ENV]').content;
window.OPTL_API_URL = document.querySelector('[name=OPTL_API_URL]').content;
window.INTERCOM_WORKSPACE_ID = document.querySelector('[name=INTERCOM_WORKSPACE_ID]').content;
window.STRIPE_PUBLISHABLE_KEY = document.querySelector('[name=STRIPE_PUBLISHABLE_KEY]').content;

(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + window.INTERCOM_WORKSPACE_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

const theme = localStorage.getItem("theme");
if (theme) {
  document.documentElement.setAttribute("data-theme", theme);
} else {
  document.documentElement.setAttribute("data-theme", "everphone");
}

(function () {
  var s = document.createElement('script');
  s.src = 'https://survey.survicate.com/workspaces/de9825f1dddfeaaf46b847cf8977e9be/web_surveys.js';
  s.async = true;
  var e = document.getElementsByTagName('script')[0];
  e.parentNode.insertBefore(s, e);
})(window);

(function(w) {
  function setSurvicateReady() {
    w.isSurvicateReady = true;
  }
  if (w._sva) {
    setSurvicateReady();
  } else {
    w.addEventListener("SurvicateReady", setSurvicateReady);
  }
})(window);